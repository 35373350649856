/**
 * Created by Mikhail Menshenin on 19.09.2024
 */

SceneDebugger.prototype.selectNode = cleverapps.extendFunc(SceneDebugger.prototype.selectNode, function (node) {
    this.preferred.push(function (node) {
        return node instanceof HutView;
    });
    this.preferred.push(function (node) {
        return node instanceof CrossView;
    });

    return this._super(node);
});