/**
 * Created by Mikhail Menshenin on 19.09.2024
 */

MissionManager.REGISTER_MISSION(Mission.TYPE_LEVEL_MASTERY, {
    available: {
        level: 0.1,
        debugMode: true
    },
    name: "LevelMastery",
    view: LevelMasteryWindow,
    stages: [
        {
            goal: 2,
            prize: {
                hard: 1
            }
        },
        {
            goal: 3,
            prize: {
                soft: 50
            }
        },
        {
            goal: 5,
            prize: {
                hard: 5
            }
        },
        {
            goal: 3,
            prize: {
                soft: 50
            }
        },
        {
            goal: 5,
            prize: {
                soft: 100
            }
        },
        {
            goal: 4,
            prize: {
                hard: 2
            }
        },
        {
            goal: 10,
            prize: {
                hard: 10
            }
        },
        {
            goal: 3,
            prize: {
                soft: 150
            }
        },
        {
            goal: 6,
            prize: {
                hard: 3
            }
        },
        {
            goal: 7,
            prize: {
                soft: 200
            }
        },
        {
            goal: 5,
            prize: {
                soft: 200
            }
        },
        {
            goal: 20,
            prize: {
                hard: 25
            }
        },
        {
            goal: 3,
            prize: {
                soft: 100
            }
        },
        {
            goal: 6,
            prize: {
                hard: 3
            }
        },
        {
            goal: 9,
            prize: {
                soft: 150
            }
        },
        {
            goal: 10,
            prize: {
                soft: 200
            }
        },
        {
            goal: 7,
            prize: {
                hard: 5
            }
        },
        {
            goal: 10,
            prize: {
                soft: 200
            }
        },
        {
            goal: 25,
            prize: {
                hard: 75
            }
        },
        {
            goal: 5,
            prize: {
                soft: 100
            }
        },
        {
            goal: 6,
            prize: {
                hard: 3
            }
        },
        {
            goal: 9,
            prize: {
                soft: 150
            }
        },
        {
            goal: 7,
            prize: {
                hard: 5
            }
        },
        {
            goal: 10,
            prize: {
                soft: 200
            }
        },
        {
            goal: 30,
            prize: {
                hard: 200
            }
        }
    ],
    startWindow: undefined,
    nudgeWindow: undefined,
    duration: "3 days",
    semaphore: Missions.SEMAPHORE_ALWAYS,
    bundle: "levelmastery",
    sideBarJson: false,
    logic: LevelMasteryMissionLogic,
    manualStart: function () {
        return true;
    }
});