/**
 * Created by Mikhail Menshenin on 23.08.2024
 */

var VillageRaidScene = VillageActionScene.extend({
    ctor: function (options) {
        this._super();

        this.village.prepareToRaid(options);

        cleverapps.audio.playSound(bundles.game.urls.slot_next_scene);
    },

    getVillage: function () {
        return aisensia.villageManager.getRaidVillage();
    },

    onSceneLoaded: function () {
        this._super(cleverapps.Environment.SCENE_VILLAGE_RAID);
    },

    addUI: function () {
        var styles = cleverapps.styles.VillageRaidScene;
        this.addControlPanel(styles);
        this.addRaidsView(styles);
    },

    addControlPanel: function (styles) {
        var controlPanel = new RaidControlPanelView(this.village);
        controlPanel.setPositionRound(styles.control);
        if (cleverapps.resolution.mode === cleverapps.WideMode.VERTICAL) {
            cleverapps.UI.fitToBox(controlPanel, styles.control);
        }
        this.addChild(controlPanel);
        controlPanel.show();
    },

    addRaidsView: function (styles) {
        var raidsView = new RaidsView(this.village);
        raidsView.setPositionRound(styles.raids.position);
        raidsView.setScale(styles.raids.scale[cleverapps.resolution.mode]);
        this.addChild(raidsView);
    },

    handleFinishEvent: function (f) {
        this.village.counter.registerStage(10, function () {
            if (this.village.isRaidFinished()) {
                f();
            }
        }.bind(this));
    }
});

cleverapps.Environment.SCENE_VILLAGE_RAID = "villageRaid";

cleverapps.styles.VillageRaidScene = {
    control: {
        width: 540,
        height: 260,

        x: { align: "center", dx: -3 },
        y: { align: "top", dy: 40 }
    },

    raids: {
        scale: [0.9, 1.15, 1.15],
        position: [{
            x: { align: "left", dx: 56 },
            y: { align: "bottom", dy: 40 }
        }, {
            x: { align: "left", dx: 78 },
            y: { align: "bottom", dy: 66 }
        }, {
            x: { align: "left", dx: 78 },
            y: { align: "bottom", dy: 66 }
        }]
    }
};
