/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var HutView = cc.Node.extend({
    avoidNode: "HutView",

    ctor: function (hut) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);

        var styles = cleverapps.styles.HutView;
        this.hut = hut;

        var image = this.image = new cc.Sprite(bundles[hut.village.getBundleId()].frames[hut.getFrameId(1)]);
        this.setContentSize(image.getContentSize());
        image.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        image.setLocalZOrder(1);
        this.addChild(image);
        image.setVisible(false);

        if (hut.village.isStateBuild()) {
            var icon = new cc.Sprite(bundles.village.frames.build_png);
            icon.setPositionRound(styles.icon);
            icon.setScale2(styles.icon.scale);
            icon.setLocalZOrder(10);
            this.addChild(icon);
            this.icon = new HidingNode(icon, HidingNode.DIR.NONE);
            cleverapps.meta.registerControl("buildIcon" + this.hut.id, this.icon);
        }

        cleverapps.UI.onClick(this, this.createListener(this.hut.onClick.bind(this.hut)), { interactiveScale: false });

        if (hut.canAttack()) {
            this.targetIcon = new cc.Sprite(bundles.village.frames.target_png);
            this.targetIcon.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
            this.targetIcon.setLocalZOrder(10);
            this.addChild(this.targetIcon);
            this.targetIcon.setScale(0.1);
            this.targetIcon.runAction(new cc.Sequence(
                new cc.Hide(),
                new cc.DelayTime(0.5 + 0.1 * hut.id),
                new cc.Show(),
                cleverapps.UI.animateScale({
                    duration: 0.1,
                    scale: 1,
                    overScaling: 1.2
                })
            ));
        }

        this.onResize();

        this.animateLevelUp(true);
        this.hut.on("changeLevel", this.animateLevelUp.bind(this));
        this.hut.on("changeDamage", this.animateDamage.bind(this));
        this.hut.on("changeUpgradeable", this.checkUpgradeable.bind(this));
        this.checkUpgradeable();

        if (cleverapps.config.debugMode) {
            hut.on("debugBorder", this.createListener(this.debugBorder.bind(this)));
            hut.on("cleanupBorder", this.createListener(this.cleanupBorder.bind(this)));
        }
    },

    updateSize: function () {
        var styles = cleverapps.styles.HutView;
        this.setScale2(styles.scale);

        var positions = styles.villages[this.hut.village.level] || styles.positions;
        var stylesPosition = styles.positions[this.hut.id];
        var position = positions[this.hut.id].map(function (pos, i) {
            return pos.x !== undefined && pos.y !== undefined ? pos : stylesPosition[i]
        });
        this.setPositionRound(position);
    },

    animateLevelUp: function (silent) {
        var newFrame = bundles[this.hut.village.getBundleId()].frames[this.hut.getFrameId()];

        if (silent && this.hut.isVisible()) {
            this.setFrame(newFrame);
            this.image.setLocalZOrder(this.hut.level);
            this.image.setVisible(true);
            if (this.icon) {
                this.icon.toggle(false, silent);
            }
            return;
        }

        if (!this.hut.isVisible()) {
            this.image.setVisible(false);
            return;
        }

        var newImage = new cc.Sprite(newFrame);
        newImage.setPositionRound({ x: { align: "center" }, y: { align: "center" } });
        newImage.setLocalZOrder(this.hut.level);
        this.addChild(newImage);

        var oldImage = this.hut.isVisible() ? this.image : new cc.Node();
        if (!this.hut.isVisible()) {
            oldImage.setContentSize(this.image.getContentSize());
            oldImage.setPosition(this.image.getPosition());
            this.addChild(oldImage);
        }
        this.image = newImage;

        this.icon.toggle(false);
        cleverapps.UI.animateUpgrade(oldImage, newImage, {
            cloud: true,
            parent: cleverapps.scenes.getMovingNode(this),
            callback: function () {
                this.icon.toggle(!this.hut.isComplete());
            }.bind(this)
        });
    },

    animateDamage: function () {
        var newFrame = bundles[this.hut.village.getBundleId()].frames[this.hut.getFrameId()];

        if (this.targetIcon && !this.hut.canAttack()) {
            this.targetIcon.removeFromParent();
        }

        this.image.runAction(
            cc.sequence(
                cc.scaleTo(0.4, 0.6),
                cc.callFunc(function () {
                    this.setFrame(newFrame);
                }.bind(this)),
                cc.scaleTo(0.4, 1)
            )
        );
    },

    setFrame: function (frame) {
        this.image.setSpriteFrame(frame);
        this.setContentSize(this.image.getContentSize());
    },

    checkUpgradeable: function () {
        if (this.icon) {
            if (this.hut.upgradeable) {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_png);
            } else {
                this.icon.target.setSpriteFrame(bundles.village.frames.build_grey_png);
            }
        }
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    HUT_LEVEL_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.LIFE_GREEN
    },
    HUT_DAMAGE_TEXT: {
        size: 56,
        color: cleverapps.styles.COLORS.COLOR_RED
    }
});

cleverapps.styles.HutView = {
    scale: [1.12, 1, 1],
    icon: {
        x: { align: "center" },
        y: { align: "bottom" },
        scale: [0.73, 0.57, 0.57],
    },

    villages: [],
    positions: [
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 600 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 400 }
        }, {
            x: { align: "center", dx: -300 },
            y: { align: "center", dy: 300 }
        }, {
            x: { align: "center", dx: -300 },
            y: { align: "center", dy: 300 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 300 },
            y: { align: "center", dy: 300 }
        }, {
            x: { align: "center", dx: 300 },
            y: { align: "center", dy: 300 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: -500 },
            y: { align: "center", dy: -100 }
        }, {
            x: { align: "center", dx: -500 },
            y: { align: "center", dy: -100 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: -200 }
        }, {
            x: { align: "center", dx: 500 },
            y: { align: "center", dy: -100 }
        }, {
            x: { align: "center", dx: 500 },
            y: { align: "center", dy: -100 }
        }]
    ],

    attention: {
        x: { align: "right", dx: -20 },
        y: { align: "top", dy: -20 },

        scale: 0.5
    }
};

RestoreProgressButton.OverlappingNodes = RestoreProgressButton.OverlappingNodes.concat("HutView");