/**
 * Created by mac on 2/5/20
 */

var HomeController = function () {
    Map2d.currentMap.counter.registerStage(1, Map2d.currentMap.fogs.calcFogStatesStage.bind(Map2d.currentMap.fogs));
    Map2d.currentMap.counter.registerStage(2, this.getNewTaskStage.bind(this));
    Map2d.currentMap.counter.registerStage(3, this.moveNextLocationStage.bind(this));

    this.home = cleverapps.home;
    this.changeStageHandler = this.home.on("change:stage", this.changeStage.bind(this));
    this.newTaskHandler = this.home.on("newTask", this.newTask.bind(this));
    this.showTargetHandler = this.home.on("showTarget", this.showTarget.bind(this));
    this.showChoiceTargetHandler = this.home.on("showChoiceTarget", this.scrollTo.bind(this));
    this.moveNextLocationHandler = this.home.on("moveNextLocation", this.moveNextLocation.bind(this));
    this.completeLocationHandler = this.home.on("completeLocation", this.showFinalDialogue.bind(this));

    this.showHelpHandler = this.home.on("showHelp", function (options) {
        var furniture = options.furniture;
        var f = options.f;
        this.showStageDialogue(furniture, f);
    }.bind(this));
};

HomeController.prototype.clear = function () {
    this.changeStageHandler.clear();
    this.newTaskHandler.clear();
    this.showChoiceTargetHandler.clear();
    this.showHelpHandler.clear();
    this.showTargetHandler.clear();
    this.moveNextLocationHandler.clear();
    this.completeLocationHandler.clear();

    this.home = undefined;
};

HomeController.prototype.getNewTaskStage = function () {
    var toOpen = this.home.getFurnitureToOpen();

    if (!toOpen.length) {
        return;
    }

    cleverapps.meta.display({
        focus: "homefixNewTask",
        actions: toOpen.map(function (furniture) {
            return function (f) {
                this.home.giveNewTask(f, furniture);
            }.bind(this);
        }.bind(this))
    });
};

HomeController.prototype.moveNextLocationStage = function () {
    if (!this.home.canUnlockNextLocation()) {
        return;
    }

    cleverapps.meta.display({
        focus: "homeMoveNextLocation",
        actions: [
            function (f) {
                this.home.completeLocation(f);
            }.bind(this),
            function (f) {
                this.home.moveNextLocation(f);
            }.bind(this)
        ]
    });
};

HomeController.prototype.changeStage = function (furniture, f) {
    furniture.removeHighlight();

    var actions = [
        function (f) {
            this.scrollTo(furniture, f);
        }.bind(this),

        function (f) {
            this.moveNextUnitStage(furniture.unit, f);
        }.bind(this),

        function (f) {
            if (furniture.hasChoices()) {
                new MakeChoiceWindow(furniture.getSwitchable(), f);
                return;
            }
            f();
        }
    ];

    if (!furniture.isComplete()) {
        actions = actions.concat([
            function (f) {
                this.showStageDialogue(furniture, f);
            }.bind(this),

            function (f) {
                this.home.animateNewTask(furniture);
                setTimeout(f, 2350);
            }.bind(this),

            function (f) {
                new FurnitureWindow(furniture);
                cleverapps.meta.onceNoWindowsListener = f;
            },

            function (f) {
                this.home.tasks.actives.forEach(function () {
                    furniture.addHighlight();
                }, this);
                f();
            }.bind(this)
        ]);
    }

    cleverapps.meta.compound(f, actions);
};

HomeController.prototype.moveNextLocation = function (f) {
    cleverapps.meta.compound(f, [
        function (f) {
            if (cleverapps.home.currentLocationId > cleverapps.home.getMaxAvailableLocationId()) {
                new AllDoneWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            } else {
                new TravelMapWindow({ moveNext: true });
                cleverapps.meta.onceNoWindowsListener = f;
            } 
        },
        function (f) {
            cleverapps.scenes.replaceScene(new HomefixScene(), f);
        }
    ]);
};

HomeController.prototype.moveNextUnitStage = function (unit, f) {
    if (!unit) {
        f();
        return;
    }

    Map2d.currentMap.fogs.wantsCalcFogStates = true;

    var throwaway = unit.findComponent(Throwaway);

    if (throwaway) {
        throwaway.animateDestroy(f);
        return;
    }

    var fixable = unit.findComponent(Fixable);

    if (fixable) {
        fixable.updateStage(f);
    }
};

HomeController.prototype.newTask = function (newbie, f) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.showStageDialogue(newbie, f);
        }.bind(this),

        function (f) {
            this.scrollTo(newbie, f);
            this.home.animateNewTask(newbie);
        }.bind(this),

        function (f) {
            this.home.tasks.actives.forEach(function () {
                newbie.addHighlight();
            }, this);
            setTimeout(f, 2200);
        }.bind(this)
    ]);
};

HomeController.prototype.showTarget = function (furniture, f) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.scrollTo(furniture, f);
        }.bind(this),

        function (f) {
            new FurnitureWindow(furniture);
            cleverapps.meta.onceNoWindowsListener = f;
        },

        function (f) {
            this.home.tasks.actives.forEach(function () {
                furniture.addHighlight();
            }, this);
            f();
        }.bind(this)
    ]);
};

HomeController.prototype.scrollTo = function (furniture, callback) {
    callback = callback || function () {};

    if (furniture.unit) {
        Map2d.currentMap.scrollToUnit(0.5, furniture.unit, {
            zoom: 1,
            callback: callback
        });
    } else {
        callback();
    }
};

HomeController.prototype.showUp = function (f) {
    cleverapps.meta.compound(f, [
        function (f) {
            setTimeout(f, 500);
        },

        function (f) {
            cleverapps.restoreProgress.update();
            f();
        },

        function (f) {
            var furniture = this.home.getMaxFurniture();
            if (furniture.id === this.home.firstFurnitureId()) {
                if (furniture.canBeUpgraded()) {
                    this.home.moveNext(furniture, f);
                } else if (!this.home.forcedAlready) {
                    this.home.forcedAlready = true;
                    cleverapps.meta.compound(f, [
                        function (f) {
                            this.showStageDialogue(furniture, f);
                        }.bind(this),

                        function (f) {
                            cleverapps.meta.wantsToPlay(f);
                        }
                    ]);
                } else {
                    f();
                }
            } else if (this.home.getFurnitureToUpgrade()) {
                new HomeTasksWindow();
                cleverapps.meta.onceNoWindowsListener = f;
            } else {
                f();
            }
        }.bind(this),

        function (f) {
            if (this.home && !this.home.isCompleted()) {
                this.home.tasks.actives.forEach(function (furniture) {
                    furniture.addHighlight();
                }, this);
            }
            f();
        }.bind(this)
    ]);
};

HomeController.prototype.showStageDialogue = function (furniture, f) {
    cleverapps.meta.compound(f, [
        function (f) {
            this.scrollTo(furniture, f);
        }.bind(this),

        function (f) {
            var furnitureInfo = furniture.getInfo();

            if (!furnitureInfo) {
                cleverapps.throwAsync("no furniture info");
                f();
                return;
            }

            var dialogue = this.createDialogue(furnitureInfo.dialogue);
            if (!dialogue) {
                f();
                return;
            }

            if (furniture.unit && furniture.unit.onGetView && furniture.unit.onGetView()) {
                dialogue.on("showUp", function () {
                    var highlighter = new UnitHighlighter(furniture.unit, { spotlight: true });
                    dialogue.on("beforeClose", highlighter.unhighlight.bind(highlighter));
                });
            }

            dialogue.on("afterClose", f);

            new DialogueView(dialogue);
        }.bind(this)
    ]);
};

HomeController.prototype.showFinalDialogue = function (f) {
    var dialogue = this.createDialogue("Final");
    dialogue.on("afterClose", f);
    new DialogueView(dialogue);
};

HomeController.prototype.createDialogue = function (dialogueName) {
    var dialogue = this.home.getConfig().dialogues[dialogueName];

    if (!dialogue) {
        return undefined;
    }

    return new Dialogue(dialogue, {
        autoClose: true,
        showUp: true
    });
};