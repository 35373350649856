/**
 * Created by Mikhail Menshenin on 20.09.2024
 */

cleverapps.overrideStyles(cleverapps.styles.HutView, {
    villages: {
        0: [[{ "x": 415, "y": 556 }, { "x": 960, "y": 335 }, { "x": 960, "y": 335 }], [{ "x": 805, "y": 1406 }, { "x": 1162, "y": 875 }, { "x": 1162, "y": 875 }], [{ "x": 385, "y": 1316 }, { "x": 1700, "y": 835 }, { "x": 1700, "y": 835 }], [{ "x": 785, "y": 1036 }, { "x": 1450, "y": 635 }, { "x": 1450, "y": 635 }], [{ "x": 495, "y": 1646 }, { "x": 820, "y": 775 }, { "x": 820, "y": 775 }]],
        1: [[{}, { "x": 1180, "y": 450 }, { "x": 1180, "y": 450 }], [{}, { "x": 1190, "y": 620 }, { "x": 1190, "y": 620 }], [{}, { "x": 590, "y": 550 }, { "x": 590, "y": 550 }], [{}, { "x": 1690, "y": 671 }, { "x": 1690, "y": 671 }], [{}, { "x": 550, "y": 260 }, { "x": 550, "y": 260 }]]
    }
});

cleverapps.overrideStyles(cleverapps.styles.CrossView, {
    villages: {
        0: [[{ "x": 605, "y": 1246 }, { "x": 984, "y": 791 }, { "x": 984, "y": 791 }], [{ "x": 785, "y": 1586 }, { "x": 1450, "y": 835 }, { "x": 1450, "y": 835 }], [{ "x": 385, "y": 1106 }, { "x": 1170, "y": 705 }, { "x": 1170, "y": 705 }], [{ "x": 635, "y": 736 }, { "x": 1180, "y": 545 }, { "x": 1180, "y": 545 }]]
    }
});