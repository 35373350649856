/**
 * Created by Slava on 20.09.2024
 */

Metha.prototype.updateControlsVisibility = cleverapps.extendFunc(Metha.prototype.updateControlsVisibility, function (silent) {
    if (cleverapps.scenes.getRunningScene() instanceof VillageScene) {
        this.controls.push("village");
    }

    this._super(silent);
});
