/**
 * Created by slava on 17/9/19
 */

var MakeChoiceWindow = CleverappsWindow.extend({
    onWindowLoaded: function (switchable, callback) {
        this.callback = callback || function () {};
        cleverapps.meta.showControlsWhileFocused("MenuBarGoldItem");

        this.switchable = switchable;
        this.switchable.animateHover();
        this.choice = undefined;

        Map2d.currentMap.scrollToUnit(0.5, switchable.unit, {
            zoom: 1,
            position: cleverapps.UI.DOCK_TOP
        });

        this._super({
            styles: cleverapps.styles.MakeChoiceWindow,
            name: "makechoicewindow",
            content: this.getContent(),
            bandButton: true
        });
    },

    getContentPadding: function () {
        var sceneSize = cleverapps.resolution.getSceneSize();
        var padding = { top: sceneSize.height * 0.57 };
        return cc.paddingAddPadding(cc.padding(padding), this.getBackgroundPadding());
    },

    getContent: function () {
        var styles = cleverapps.styles.MakeChoiceWindow;

        this.items = [];
        for (var i = 0; i < this.switchable.choices.length; i++) {
            this.items.push(new MakeChoiceWindowItem({
                choice: i,
                isPreviousChoice: this.switchable.choice === i,
                onClick: this.selectChoice.bind(this, i),
                switchable: this.switchable,
                params: this.switchable.choices[i]
            }));
        }

        var choicesLine = new cleverapps.Layout(this.items, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: styles.choicesLine.margin
        });

        var bg = cleverapps.UI.createScale9Sprite(bundles.make_choice_window.frames.window_bg_png);
        bg.setContentSize2(choicesLine.width + 2 * styles.bg.padding, styles.bg.height);
        choicesLine.setPositionRound(styles.choicesLine.position);
        bg.addChild(choicesLine);

        this.selectedTitle = cleverapps.UI.generateOnlyText("MakeChoiceWindow.title", cleverapps.styles.FONTS.MAKE_CHOICE_WINDOW_TITLE);
        this.selectedTitle.setPositionRound(styles.choicesLine.title.position);
        this.selectedTitle.originalFontSize = this.selectedTitle.getFontSize();
        this.selectedTitle.fitTo(bg.width);
        bg.addChild(this.selectedTitle);

        var selectButton = new cleverapps.UI.Button({
            content: new cc.Sprite(bundles.make_choice_window.frames.checkmark_png),
            type: cleverapps.styles.UI.Button.Images.small_button_green,
            width: styles.selectButton.width,
            height: styles.selectButton.height,
            onClicked: this.finish.bind(this)
        });

        selectButton.setPositionRound(styles.selectButton.position);
        bg.addChild(selectButton);

        this.selectChoice(this.switchable.choice !== undefined ? this.switchable.choice : 0);
        return bg;
    },

    selectChoice: function (choice) {
        if (this.selected !== choice) {
            if (this.selected !== undefined) {
                this.items[this.selected].unSelect();
            }
            this.selected = choice;
            this.items[this.selected].select();
            this.switchable.showChoice(choice);
        }
    },

    makeChoice: function (choice) {
        var eventPrefix = this.switchable.choice === undefined ? cleverapps.EVENTS.MAKE_CHOICE : cleverapps.EVENTS.REDO_CHOICE;
        var choiceEvent = eventPrefix + choice + this.switchable.name;
        cleverapps.eventLogger.logEvent(choiceEvent);
        this.switchable.setChoice(choice, this.callback);
        this.choice = choice;
        this.close();
    },

    onShow: function () {
        this._super();
        if (!this.unitHighlighter) {
            this.unitHighlighter = new UnitHighlighter(this.switchable.unit, { spotlight: true, ground: false });
        }
    },

    onHide: function () {
        this._super();
        if (this.unitHighlighter) {
            this.unitHighlighter.unhighlight();
            delete this.unitHighlighter;
        }
    },

    finish: function () {
        var choice = this.selected;

        if (this.items[choice].ad) {
            cleverapps.rewardedAdsManager.playRewarded(AdsLimits.TYPES.CHOICE, this.createListener(function () {
                this.makeChoice(choice);
            }.bind(this)));
        } else if (this.items[choice].price) {
            var price = this.items[choice].price;
            if (levels.user.spendHard(cleverapps.EVENTS.SPENT.HOMEFIX_CHOICE, price)) {
                this.makeChoice(choice);
            }
        } else {
            this.makeChoice(choice);
        }
    },

    onClose: function () {
        if (this.choice === undefined) {
            this.callback();
            this.switchable.showChoice();
        }
        this.unitHighlighter.unhighlight();
        this.switchable.stopAnimateHover();
    },

    listBundles: function () {
        return ["make_choice_window"];
    }
});

cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    MAKE_CHOICE_WINDOW_TITLE: {
        size: 30,
        color: cleverapps.styles.COLORS.COLOR_VERY_DARK_GRAY
    }
});

cleverapps.styles.MakeChoiceWindow = {
    windowShowUpAnimation: {
        name: "fromBelow",
        force: true
    },
    noBackground: true,

    bg: {
        padding: 20,
        height: 390
    },

    selectButton: {
        width: 265,
        height: 90,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "bottom", dy: -29 }
        }
    },
    title: {
        x: { align: "center" },
        y: { align: "top", dy: -30 }
    },

    choicesLine: {
        margin: -21,
        position: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 10 }
        },
        title: {
            position: {
                x: { align: "center" },
                y: { align: "top", dy: -15 }
            }
        }
    }
};
