/**
 * Created by mac on 2/3/20
 */

var Home = function () {
    cleverapps.EventEmitter.call(this);

    this.onSave = function () {};
    this.onChangeStars = function () {};

    this.currentLocationId = 0;
    this.stars = 0;
    this.locations = {};

    this.furnitureId = this.firstFurnitureId();

    this.load();
    this.loadLocationData(this.currentLocationId);

    this.locationsAmount = this.calcLocations();
    this.tasks = new HomeTasks(this);
};

Home.prototype = Object.create(cleverapps.EventEmitter.prototype);
Home.prototype.constructor = Home;

Home.prototype.amountLevelStars = function (level) {
    return level.isHard() ? 3 : 1;
};

Home.prototype.onUnitsLoaded = function () {
    if (!this.tasks.actives) {
        this.tasks.setActives();
    }
};

Home.prototype.getCurrentLocationProgress = function () {
    var goal = 0;
    var current = 0;
    this.getFurniture().forEach(function (furniture) {
        furniture.stages.forEach(function (stage) {
            goal += stage.cost;
            if (furniture.id <= this.furnitureId) {
                current += stage.cost;
            }
        }.bind(this));
    }.bind(this));
    return {
        goal: goal,
        current: current
    };
};

Home.prototype.getMaxAvailableLocationId = function () {
    return Math.min(this.currentLocationId, this.locationsAmount - 1);
};

Home.prototype.isLocationAvailable = function (locationId) {
    return locationId > this.currentLocationId - Home.LOCATIONS_AMOUNT && locationId <= this.currentLocationId;
};

Home.prototype.gamePlayed = function (outcome, game) {
    if (outcome === GameBase.OUTCOME_VICTORY) {
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_LEVEL);
        cleverapps.playSession.set(cleverapps.EVENTS.METHA_LEVEL, true);

        game.rewards.homeStar = this.amountLevelStars(game.level);
    }
};

Home.prototype.showChoiceTarget = function (furniture, f) {
    this.trigger("showChoiceTarget", furniture, f);
};

Home.prototype.showTarget = function (furniture, f) {
    this.trigger("showTarget", furniture, f);
};

Home.prototype.firstFurnitureId = function () {
    return -1;
};

Home.prototype.loadLocationData = function (locationNo) {
    locationNo = locationNo || 0;

    var location = {};

    var config = bundles["location_" + locationNo].jsons.data_json.getJson();

    location.furniture = [];

    if (config) {
        location.config = config;

        location.furniture = location.config.items.map(function (data) {
            var item = new Furniture(this, data);
            return item;
        }, this);
    }

    this.location = location;
};

Home.prototype.load = function () {
    var data = cleverapps.dataLoader.load(DataLoader.TYPES.HOMEFIX);
    this.updateData(data);
};

Home.prototype.getConfig = function () {
    return this.location.config;
};

Home.prototype.getFurniture = function () {
    return this.location.furniture;
};

Home.prototype.getCurrentFurnitureId = function () {
    return this.furnitureId;
};

Home.prototype.setCurrentFurnitureId = function (newId) {
    this.furnitureId = newId;
    this.save();
};

Home.prototype.getFurnitureById = function (id) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].id === id) {
            return furniture[i];
        }
    }
    return undefined;
};

Home.prototype.getFurnitureByName = function (name) {
    var furniture = this.getFurniture();

    for (var i in furniture) {
        if (furniture[i].name === name) {
            return furniture[i];
        }
    }
    return undefined;
};

Home.prototype.getMaxFurniture = function () {
    return this.getFurnitureById(this.getCurrentFurnitureId());
};

Home.prototype.updateInfo = function (serverData) {
    this.updateData(serverData);
    this.save(true);
};

Home.prototype.setMapInfo = function (locationId, mapInfo) {
    this.locations[locationId] = mapInfo;
    this.save();
};

Home.prototype.getMapInfo = function (locationId) {
    return this.locations[locationId] || {
        fogs: [],
        units: {}
    };
};

Home.prototype.updateData = function (data) {
    if (!data) {
        return;
    }
    this.oldItems = data.items;

    this.currentLocationId = data.currentLocationId || 0;
    this.stars = data.stars;
    this.locations = data.locations || {};

    if (data.currentFurnitureId !== undefined) {
        this.furnitureId = data.currentFurnitureId;
    } else {
        this.furnitureId = data.furnitureId;
    }
};

Home.prototype.getInfo = function () {
    return {
        currentLocationId: this.currentLocationId,
        stars: this.stars,
        furnitureId: this.furnitureId,
        locations: this.locations
    };
};

Home.prototype.trim = function () {
    Object.keys(this.locations).forEach(function (key) {
        if (!this.isLocationAvailable(key)) {
            delete this.locations[key];
        }
    }.bind(this));
};

Home.prototype.save = function (fromSever) {
    this.trim();
    cleverapps.dataLoader.save(DataLoader.TYPES.HOMEFIX, this.getInfo());
    if (!fromSever) {
        cleverapps.synchronizer.addUpdateTask("metha");
    }
    this.onSave();
};

if (cleverapps.config.debugMode) {
    Home.prototype.reset = function () {
        this.stars = 0;
        this.currentLocationId = 0;
        this.furnitureId = this.firstFurnitureId();
        this.locations = {};

        this.save();
    };

    Home.prototype.getNextId = function () {
        var furniture = this.getFurniture();
        var currentFurnitureId = this.getCurrentFurnitureId();

        for (var i = 0; i < furniture.length; i++) {
            var currentFurniture = furniture[i];

            if (currentFurniture.id === currentFurnitureId) {
                var nextFurniture = furniture[i + 1];
                return nextFurniture ? nextFurniture.id : currentFurnitureId + 1;
            }
        }
    };
}

Home.prototype.giveNewTask = function (f, furniture) {
    this.tasks.add(furniture);
    this.trigger("newTask", furniture, f);
};

Home.prototype.getFurnitureToUpgrade = function () {
    for (var id in this.tasks.actives) {
        if (this.tasks.actives[id].canBeUpgraded()) {
            return this.tasks.actives[id];
        }
    }

    return undefined;
};

Home.prototype.getFurnitureToOpen = function () {
    return this.getFurniture().filter(function (furniture) {
        return furniture.readyToOpen() && !this.tasks.isActive(furniture);
    }.bind(this));
};

Home.prototype.getActiveFurniture = function () {
    return this.getFurniture().filter(function (furniture) {
        return furniture.readyToOpen() && furniture.id <= this.getCurrentFurnitureId();
    }.bind(this));
};

Home.prototype.moveNext = function (furniture, f) {
    var cost = furniture.getInfo().cost;
    this.stars -= cost;

    if (!furniture.nextStage()) {
        this.tasks.onFurnitureCompleted(furniture);
        cleverapps.eventLogger.logEvent(cleverapps.EVENTS.METHA_ITEM + "_" + this.currentLocationId + "_" + furniture.id);
    }
    this.trigger("change:stage", furniture, f);

    this.save();
    this.onChangeStars();
};

Home.prototype.earnStars = function (stars, silent) {
    this.stars += stars;

    this.save();

    if (!silent) {
        this.onChangeStars();
    }
};

Home.prototype.getLatestNotCompletedFurniture = function () {
    if (this.tasks.actives.length > 0) {
        return this.tasks.actives[0];
    }

    var furniture = this.getFurniture();
    return furniture[furniture.length - 1];
};

Home.prototype.isCompleted = function () {
    return this.currentLocationId > this.getMaxAvailableLocationId();
};

Home.prototype.animateNewTask = function (furniture) {
    this.trigger("animateNewTask", furniture);
};

Home.prototype.setLocationAvailable = function (locationId) {
    if (locationId === this.currentLocationId + 1 && !cleverapps.environment.isEditorScene()) {
        this.nextLocationAvailable = true;
    }
};

Home.prototype.canUnlockNextLocation = function () {
    return this.nextLocationAvailable;
};

Home.prototype.moveNextLocation = function (f) {
    this.nextLocationAvailable = false;
    this.currentLocationId++;
    this.furnitureId = this.firstFurnitureId();
    this.loadLocationData(this.currentLocationId);
    this.tasks.actives = undefined;
    this.save();
    this.trigger("moveNextLocation", f);
};

Home.prototype.completeLocation = function (f) {
    this.trigger("completeLocation", f);
};

Home.prototype.getCompleteLocationReward = function () {
    return {
        hard: 200
    };
};

Home.prototype.calcLocations = function () {
    var amount = 0;
    while (bundles["location_" + amount]) {
        amount++;
    }

    return amount;
};

Home.prototype.processChatMessage = function (message) {
    if (!message.metha.home) {
        return;
    }

    this.load(Home.GetFurnitureProgress(message.home.metha.level, message.metha.home.furniture, message.metha.home.stage));
    this.save();
};

Home.GetFurnitureProgress = function (level, furnitureIndex, currentStage) { /// ?
    var home = cleverapps.home.location;

    var data = {
        furnitureId: home.furniture[furnitureIndex].id,
        stars: home.stars,
        items: {}
    };

    home.furniture.forEach(function (furniture, index) {
        var choice;
        var stage = 0;
        if (index < furnitureIndex) {
            if (furniture.hasChoices()) {
                choice = furniture.choice || 0;
            }
            stage = furniture.amountStages();
        } else if (index === furnitureIndex) {
            stage = currentStage;
        }

        data.items[furniture.id] = {
            choice: choice,
            stage: stage
        };
    });

    return data;
};

Home.LOCATIONS_AMOUNT = 3;
