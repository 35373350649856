/**
 * Created by Mikhail Menshenin on 09.07.2024
 */

var VillageView = cc.Node.extend({
    ctor: function (village) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setPositionRound({ x: { align: "center" }, y: { align: "center" } });

        this.village = village;

        this.hutViews = village.huts.map(function (hut) {
            var hutView = new HutView(hut);
            this.addChild(hutView);
            return hutView;
        }.bind(this));

        this.crossViews = village.crosses.map(function (cross) {
            var crossView = new CrossView(cross);
            this.addChild(crossView);
            return crossView;
        }.bind(this));

        this.movingNode = new cc.Node();
        this.addChild(this.movingNode);

        this.updateSize();
    },

    updateSize: function () {
        var scene = cleverapps.scenes.getRunningScene();
        this.setContentSize(scene.background.getContentSize());
    }
});
