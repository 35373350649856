/**
 * Created by Mikhail Menshenin on 26.08.2024
 */

var Cross = function (village, id) {
    cleverapps.EventEmitter.call(this);
    this.village = village;
    this.id = id;
    this.raided = false;
};

Cross.prototype = Object.create(cleverapps.EventEmitter.prototype);
Cross.prototype.constructor = Cross;

Cross.prototype.raid = function () {
    if (this.village.counter.isActive()) {
        return;
    }

    if (!this.raided && this.village.canRaid()) {
        this.raided = true;
        
        var gold = this.village.getGoldByRaid();
        this.village.raid();
        this.trigger("raided", gold, function () {
            this.village.raided(gold);
        }.bind(this));
    }
};

Cross.prototype.getInfo = function () {
    return {
        raided: this.raided
    };
};
