/**
 * Created by Mikhail Menshenin on 20.08.2024
 */

var VillagePlayers = function () {
    this.running = true;
    this.type = "village_players";
    this.results = cleverapps.competitionPlayers.listFakePlayers(5);
    this.raided = [];
    this.raidPlayer = undefined;

    if (this.countFakePlayers() > 0) {
        this.onPlayersAvailable();
    }
}

VillagePlayers.prototype.countFakePlayers = Competition.prototype.countFakePlayers;
VillagePlayers.prototype.onPlayersAvailable = Competition.prototype.onPlayersAvailable;
VillagePlayers.prototype.replaceFakePlayers = Competition.prototype.replaceFakePlayers;

VillagePlayers.prototype.save = function () {
    cleverapps.UI.Avatar.preload(this.results.filter(function (player) {
        return !player.fake;
    }));
    if (aisensia.villageManager && aisensia.villagePlayers) {
        var village = aisensia.villageManager.getRaidVillage();
        village.updatePlayer();
    }
};

VillagePlayers.prototype.getAttackPlayer = function () {
    return cleverapps.Random.choose(this.results.filter(function (player) {
        return player !== this.raidPlayer;
    }.bind(this)));
};

VillagePlayers.prototype.getRaidPlayer = function () {
    if (!this.raidPlayer) {
        var raidPlayer = cleverapps.Random.choose(this.getPlayersToRaid());

        if (raidPlayer.villageLevel === undefined) {
            raidPlayer.villageLevel = cleverapps.Random.random(0, 6);
        }

        this.raidPlayer = raidPlayer;
    }

    return this.raidPlayer;
}

VillagePlayers.prototype.resetRaidPlayer = function () {
    this.raided.push(this.raidPlayer);
    if (!this.getPlayersToRaid().length) {
        this.raided.length = 0;
    }
    this.raidPlayer = undefined;
};

VillagePlayers.prototype.getPlayersToRaid = function () {
    return cleverapps.substract(this.results, this.raided, function (player) {
        return player.id;
    });
};