/**
 * Created by Mikhail Menshenin on 29.08.2024
 */

var RaidControlPanelView = cc.Node.extend({
    ctor: function (village) {
        this._super();

        var styles = cleverapps.styles.RaidControlPanelView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var controlPanelBg = cleverapps.UI.createScale9Sprite(bundles.village.frames.control_panel_bg);
        controlPanelBg.setContentSize(styles);
        controlPanelBg.setPosition(this.width / 2, this.height / 2);
        this.addChild(controlPanelBg);

        var player = village.player;
        var avatar = this.avatar = new cleverapps.UI.Avatar(player);
        avatar.setPositionRound(styles.avatar);
        avatar.setScale(1.3);
        this.addChild(avatar);

        var nameText = this.nameText = cleverapps.UI.generateOnlyText("%{name}'s\ntreasure:", cleverapps.styles.FONTS.RAID_NAME_TEXT, { name: cleverapps.UI.cutPlayerName(player.name, 8) });
        nameText.setPositionRound(styles.name);
        this.addChild(nameText);

        var goldText = this.goldText = new TextWithIcon(aisensia.getNumberWithCommas(village.gold) + " " + TextWithIcon.ICONS_BY_NAME.soft, styles.gold.options);
        goldText.setPositionRound(styles.gold);
        this.addChild(goldText);

        var labelText = this.labelText = cleverapps.UI.generateOnlyText("You\nstole:", cleverapps.styles.FONTS.RAID_NAME_TEXT);
        labelText.setPositionRound(styles.label);
        this.addChild(labelText);
        labelText.setOpacity(0);

        var earnedText = this.earnedText = this.earnedText = new TextWithIcon(aisensia.getNumberWithCommas(village.goldRaided) + " " + TextWithIcon.ICONS_BY_NAME.soft, styles.earned.options);
        earnedText.setAnchorPoint(0, 0);
        earnedText.setPositionRound(styles.earned);
        this.addChild(earnedText);
        earnedText.setCascadeOpacityEnabledRecursively(true);
        earnedText.setOpacity(0);
        this.raidedAlready = village.goldRaided;

        village.on("changeGoldRaided", this.createListener(this.animateChangeEarnedGold.bind(this, village)));

        this.setContentSize(controlPanelBg.getContentSize());
    },

    show: function () {
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.MoveBy(0, 0, this.height),
            new cc.DelayTime(0.2),
            new cc.Show(),
            new cc.MoveBy(0.3, 0, -this.height).easing(cc.easeOut(3)),
            new cc.CallFunc(this.showRaidedBlock.bind(this))
        ));
    },

    showRaidedBlock: function () {
        this.labelText.runAction(
            new cc.FadeIn(0.2)
        );

        this.earnedText.runAction(
            new cc.FadeIn(0.2)
        );
    },

    animateChangeEarnedGold: function (village) {
        this.earnedText.runAction(AnimationsLibrary.countTo(this.earnedText, village.goldRaided / 1000, {
            duration: 0.5,
            from: this.raidedAlready / 1000,
            getString: function (value) {
                return aisensia.getNumberWithCommas(value * 1000) + " " + TextWithIcon.ICONS_BY_NAME.soft;
            }
        }));
        this.raidedAlready = village.goldRaided;
    },

    animateRaidPlayer: function (cb) {
        this.labelText.setVisible(false);
        this.earnedText.setVisible(false);

        var position = this.getPosition();
        this.setPosition(position.x, position.y + this.height);
        this.runAction(new cc.Sequence(
            new cc.DelayTime(0.4),
            new cc.MoveBy(0.1, 0, -this.height).easing(cc.easeOut(1)),
            new cc.DelayTime(0.8),
            new cc.CallFunc(cb || function () { })
        ));

        this.nameText.setOpacity(0);
        this.nameText.runAction(new cc.Sequence(
            new cc.DelayTime(0.6),
            new cc.FadeIn(0.1)
        ));

        this.goldText.setCascadeOpacityEnabledRecursively(true);
        this.goldText.setOpacity(0);
        this.goldText.setScale(0);
        this.goldText.runAction(new cc.Sequence(
            new cc.DelayTime(0.7),
            new cc.Spawn(
                new cc.FadeIn(0.1),
                new cc.scaleTo(0.2, 1).easing(cc.easeBackOut())
            )
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    RAID_GOLD_TEXT: {
        name: "nostroke",
        size: 36,
        color: new cc.Color(73, 167, 65),
        stroke: undefined,
        shadow: undefined
    },
    RAID_NAME_TEXT: {
        name: "nostroke",
        lineHeight: 41,
        size: 36,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.RaidControlPanelView = {
    width: 688,
    height: 260,

    avatar: {
        x: { align: "left", dx: 28 },
        y: { align: "center", dy: -12 },

        padding: {
            x: 10,
            top: 8,
            bottom: 12
        }
    },

    name: {
        x: { align: "left", dx: 204 },
        y: { align: "center", dy: 16 },
    },

    gold: {
        x: { align: "left", dx: 204 },
        y: { align: "center", dy: -60 },

        options: {
            font: cleverapps.styles.FONTS.RAID_GOLD_TEXT
        }
    },

    label: {
        x: { align: "left", dx: 440 },
        y: { align: "center", dy: 16 },
    },

    earned: {
        x: { align: "left", dx: 440 },
        y: { align: "center", dy: -60 },

        options: {
            font: cleverapps.styles.FONTS.RAID_GOLD_TEXT
        }
    }
};