/**
 * Created by Mikhail Menshenin on 03.07.2024
 */

var CoinKingdomScene = GameSceneBase.extend({
    onSceneLoaded: function () {
        var styles = cleverapps.styles.CoinKingdomScene;

        this._super(cleverapps.Environment.SCENE_SLOT_MACHINE);

        var slotMachine = this.slotMachine = aisensia.slotMachine;
        var cloudsBundle = bundles[this.getBackgroundStyles().bundle];

        var slotMachineView = new SlotMachineView(slotMachine)
        slotMachineView.setAnchorPoint(0.5, 0);
        slotMachineView.setScale2(styles.slotmachine.scale);
        slotMachineView.setPositionRound(styles.slotmachine.position);
        this.addChild(slotMachineView);

        var clouds2 = this.makeCloudsLayer(cloudsBundle.frames.clouds2_png);
        clouds2.setAnchorPoint(0.5, 1);
        clouds2.setPositionRound(styles.clouds2.position);
        this.addChild(clouds2);

        var button = new SlotMachineButtonView(slotMachine);
        button.setAnchorPoint(0.5, 0);
        button.setPositionRound(styles.button.position);
        this.addChild(button);

        var clouds3 = this.makeCloudsLayer(cloudsBundle.frames.clouds3_png);
        clouds3.setAnchorPoint(0.5, 1);
        clouds3.setPositionRound(styles.clouds3.position);
        this.addChild(clouds3);

        this.addSlotMachineToolbar();


        if (aisensia.levelMastery.isAvailable) {
            var levelMastery = this.levelMastery = new LevelMasteryView({ type: "scene" });
            levelMastery.setPositionRound(cleverapps.styles.LevelMasteryView);
            this.addChild(levelMastery);
            levelMastery.setLocalZOrder(15);
            levelMastery.registerControl();
            levelMastery.updateSize();
        }
        this.upMenuContainer.addShields();
    },

    makeCloudsLayer: function (frame) {
        var sprites = cleverapps.rangeArray(0, 2).map(function () {
            return new cc.Sprite(frame);
        });
        return new cleverapps.Layout(sprites, {
            direction: cleverapps.UI.HORIZONTAL,
            margin: 0,
            padding: 0
        });
    },

    addSlotMachineToolbar: function () {
        var toolbarView = new ToolbarView();
        toolbarView.setLocalZOrder(1);
        this.addChild(toolbarView);

        this.downToolBarControl = new HidingNode(toolbarView, cleverapps.UI.VERTICAL);
        cleverapps.meta.registerControl("toolbar", this.downToolBarControl);
    },

    onExit: function () {
        this._super();

        this.slotMachine.purge();
    },

    listBundles: function () {
        var bundles = this._super() || [];

        bundles.push("transition_attack");
        bundles.push("transition_raid");

        return bundles;
    },

    _closeAction: cleverapps.FixedWidthScene.prototype._closeAction
});

GameScene = CoinKingdomScene;

cleverapps.styles.CoinKingdomScene = {
    slotmachine: {
        scale: [0.87, 1, 1],
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 276 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 256 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 256 }
        }]
    },

    button: {
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 124 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 144 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 144 }
        }]
    },

    clouds2: {
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: 160 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 60 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 60 }
        }]
    },

    clouds3: {
        position: [{
            x: { align: "center" },
            y: { align: "bottom", dy: -20 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 36 }
        }, {
            x: { align: "center" },
            y: { align: "bottom", dy: 36 }
        }]
    }
};
