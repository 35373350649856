/**
 * Created by Mikhail Menshenin on 26.08.2024
 */

var CrossView = cc.Node.extend({
    ctor: function (cross, id) {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        this.setVisible(cross.village.isStateRaid());
        this.cross = cross;
        this.id = id;

        var styles = cleverapps.styles.CrossView;

        var icon = this.icon = new cc.Sprite(bundles.village.frames.cross_png);
        icon.setPositionRound(styles.icon);
        this.addChild(icon);
        icon.setVisible(!cross.raided);

        var positions = styles.villages[cross.village.level] ? styles.villages[cross.village.level] : styles.positions;
        var stylesPosition = styles.positions[cross.id];
        var position = positions[cross.id].map(function (pos, i) {
            return pos.x !== undefined && pos.y !== undefined ? pos : stylesPosition[i]
        });
        this.setContentSize2(icon.getContentSize());
        this.setPositionRound(position);

        var pit = this.pit = new cc.Sprite(bundles.village.frames.pit_png);
        pit.setPositionRound(styles.pit);
        this.addChild(pit);
        pit.setVisible(cross.raided);

        cleverapps.UI.onClick(this, this.createListener(cross.raid.bind(cross)), { interactiveScale: false });

        cross.on("raided", this.createListener(this.animateDig.bind(this)));

        if (cleverapps.config.debugMode) {
            this.setVisible(cross.village.isStateRaid() || !!cleverapps.flags.villageCrossEnabled);
            cleverapps.flags.on("change:villageCrossEnabled", function () {
                this.setVisible(!!cleverapps.flags.villageCrossEnabled);
            }.bind(this));
        }

        if (cross.village.isStateRaid() && !cross.raided) {
            this.show();
        }
        this.setupChildren();
    },

    setupChildren: function () {
        var styles = cleverapps.styles.CrossView;
        this.setScale(styles.scale[cleverapps.resolution.mode]);
    },

    show: function () {
        this.setScale(0.1);
        this.runAction(new cc.Sequence(
            new cc.Hide(),
            new cc.DelayTime(0.5 + 0.1 * this.cross.id),
            new cc.Show(),
            new cc.CallFunc(function () {
                cleverapps.audio.playSound(bundles.main.urls.slot_target);
            }),
            cleverapps.UI.animateScale({
                duration: 0.1,
                scale: 1,
                overScaling: 1.2
            })
        ));
    },

    animateDig: function (gold, cb) {
        var styles = cleverapps.styles.CrossView;
        this.icon.runAction(
            new cc.Sequence(
                new cc.PlaySound(bundles.main.urls.slot_raid_dig),
                new cc.ScaleTo(0.3, 0.6).easing(cc.easeCubicActionIn()),
                new cc.Hide(),
                new cc.CallFunc(function () {
                    this.pit.setScale(0.6);
                    this.pit.setVisible(true);
                    this.pit.runAction(new cc.Sequence(
                        new cc.ScaleTo(0.3, 1).easing(cc.easeCubicActionOut()),
                        new cc.CallFunc(cb || function () { })
                    ));
                }.bind(this))
            )
        );
    }
});

cleverapps.styles.CrossView = {
    scale: [1.6, 1, 1],
    pit: {
        x: { align: "center" },
        y: { align: "center" }
    },
    icon: {
        x: { align: "center" },
        y: { align: "center" }
    },

    villages: [],
    positions: [
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 400 }
        }, {
            x: { align: "center", dx: -250 },
            y: { align: "center", dy: 100 }
        }, {
            x: { align: "center", dx: -250 },
            y: { align: "center", dy: 100 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 200 }
        }, {
            x: { align: "center", dx: 100 },
            y: { align: "center", dy: 200 }
        }],
        [{
            x: { align: "center", dx: -200 },
            y: { align: "center", dy: 0 }
        }, {
            x: { align: "center", dx: 280 },
            y: { align: "center", dy: 30 }
        }, {
            x: { align: "center", dx: 280 },
            y: { align: "center", dy: 30 }
        }],
        [{
            x: { align: "center", dx: 200 },
            y: { align: "center", dy: -200 }
        }, {
            x: { align: "center", dx: 220 },
            y: { align: "center", dy: -200 }
        }, {
            x: { align: "center", dx: 220 },
            y: { align: "center", dy: -200 }
        }]
    ]
};