/**
 * Created by Mikhail Menshenin on 15.08.2024
 */

var SlotMachineActions = {
    attack: function (cb) {
        cb = cb || function () { };
        cleverapps.meta.displayWhenFreeFocus({
            focus: "VillageAttackScene",
            control: [],
            action: function (f) {
                var scene = new VillageAttackScene();
                scene.transitionType = Transition.TYPE_ATTACK;
                cleverapps.scenes.replaceScene(scene, f);
                cb();
            }
        });
    },

    raid: function (cb) {
        cb = cb || function () { };
        cleverapps.meta.displayWhenFreeFocus({
            focus: "VillageRaidScene",
            control: [],
            action: function (f) {
                var scene = new VillageRaidScene();
                scene.transitionType = Transition.TYPE_RAID;
                cleverapps.scenes.replaceScene(scene, f);
                aisensia.villagePlayers.resetRaidPlayer();
                cb();
            }
        });
    }
};