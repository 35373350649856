/**
 * Created by Mikhail Menshenin on 19.08.2024
 */

var SlotMachineSymbolView = cc.Node.extend({
    ctor: function (symbol) {
        this._super();

        this.symbol = symbol;
        this.reel = this.symbol.reel;

        var styles = cleverapps.styles.SlotMachineSymbolView;

        this.setContentSize2(styles);
        this.setAnchorPoint(0.5, 0.5);

        var icon = this.icon = new cc.Sprite(symbol.icon);
        this.addChild(icon);
        icon.setPositionRound(this.width / 2, this.height / 2);

        if (symbol.sliding_icon) {
            var slidingIcon = this.slidingIcon = new cc.Sprite(symbol.sliding_icon);
            this.addChild(slidingIcon);
            slidingIcon.setPositionRound(this.width / 2, this.height / 2);
            slidingIcon.setVisible(false);
        }

        symbol.on("move", this.createListener(this.move.bind(this)));
        symbol.on("spin", this.createListener(this.spinOneDegree.bind(this)));
        symbol.on("reset", this.createListener(this.reset.bind(this)));

        this.setPositionRound(this.calcPosition());

        this.setCascadeColorEnabled(true);
    },

    reset: function () {
        this.stopAllActions();

        if (this.shine) {
            this.shine.removeFromParent();
            delete this.shine;
        }

        this.setColor(cleverapps.styles.COLORS.WHITE);
    },

    move: function () {
        this.setPositionRound(this.calcPosition());
    },

    spinOneDegree: function (duration, isFast, isLast) {
        var styles = cleverapps.styles.SlotMachineSymbolView;
        var targetPos = this.calcPosition();

        if (this.slidingIcon) {
            this.icon.setVisible(!isFast);
            this.slidingIcon.setVisible(isFast);
        }

        if (isLast) {
            this.runAction(new cc.Sequence(
                new cc.MoveBy(duration / 1000, 0, -styles.height * 9 / 8).easing(cc.easeBackOut(0.1)),
                new cc.PlaySound(bundles.game.urls.slot_stop, { throttle: 0 }),
                new cc.CallFunc(function () {
                    this.setPositionRound(targetPos);
                }.bind(this))
            ));
        } else {
            this.runAction(new cc.Sequence(
                new cc.MoveBy(duration / 1000, 0, -styles.height),
                new cc.CallFunc(function () {
                    this.setPositionRound(targetPos);
                }.bind(this))
            ));
        }
    },

    calcPosition: function () {
        var styles = cleverapps.styles.SlotMachineSymbolView;

        var bottomSymbolIndex = (this.reel.getCurrent().index + 1) % this.reel.symbols.length;
        var indexOffset = (bottomSymbolIndex - this.symbol.index + this.reel.symbols.length) % this.reel.symbols.length;

        var centerOffset = cleverapps.styles.SlotMachineReelView.height / 2 - (styles.height / 2 + styles.height);

        return cc.p(this.width / 2, styles.height / 2 + indexOffset * styles.height + centerOffset);
    }
});

cleverapps.styles.SlotMachineSymbolView = {
    width: 145,
    height: 125
};