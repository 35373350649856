/**
 * Created by Mikhail Menshenin on 04.09.2024
 */

var AttackControlPanelView = cc.Node.extend({
    ctor: function (village) {
        this._super();

        var styles = cleverapps.styles.AttackControlPanelView;
        this.setAnchorPoint(0.5, 0.5);
        this.setContentSize(styles);

        var controlPanelBg = cleverapps.UI.createScale9Sprite(bundles.village.frames.control_panel_bg);
        controlPanelBg.setContentSize(styles);
        controlPanelBg.setPosition(this.width / 2, this.height / 2);
        this.addChild(controlPanelBg);

        var player = village.player;
        var avatar = this.avatar = new cleverapps.UI.Avatar(player);
        avatar.setPositionRound(styles.avatar);
        avatar.setScale(styles.avatar.scale);
        this.addChild(avatar);

        var nameText = cleverapps.UI.generateOnlyText("%{name}'s village", cleverapps.styles.FONTS.ATTACK_NAME_TEXT, { name: cleverapps.UI.cutPlayerName(player.name, 8) });
        nameText.setPositionRound(styles.name);
        this.addChild(nameText);

        var revengeButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_red,
            width: styles.revenge.width,
            height: styles.revenge.height,
            content: new TextWithIcon("** revenge", {
                icons: {
                    "**": bundles.village.frames.revenge_icon
                },
                iconScale: 1.5
            }),
            onClicked: function () { }
        });
        revengeButton.setPositionRound(styles.revenge);
        this.addChild(revengeButton);

        var friendsButton = new cleverapps.UI.Button({
            type: cleverapps.styles.UI.Button.Images.button_green,
            width: styles.friends.width,
            height: styles.friends.height,
            content: new TextWithIcon("** friends", {
                icons: {
                    "**": bundles.village.frames.friends_icon
                },
                iconScale: 1.5
            }),
            onClicked: function () { }
        });
        friendsButton.setPositionRound(styles.friends);
        this.addChild(friendsButton);
    },

    show: function () {
        var position = this.getPosition();
        this.setPosition(position.x, position.y + this.height);
        this.runAction(
            new cc.MoveBy(0.4, 0, -this.height).easing(cc.easeOut(1))
        );
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    ATTACK_NAME_TEXT: {
        size: 36,
        color: cleverapps.styles.COLORS.DARK_TEXT_COLOR
    }
});

cleverapps.styles.AttackControlPanelView = {
    width: 570,
    height: 270,

    padding: {
        x: 100
    },

    avatar: {
        x: { align: "left", dx: 40 },
        y: { align: "center", dy: 40 },

        scale: 1,

        padding: {
            x: 10,
            top: 8,
            bottom: 12
        }
    },

    name: {
        x: { align: "left", dx: 180 },
        y: { align: "center", dy: 40 }
    },

    revenge: {
        width: 250,
        height: 80,
        x: { align: "right", dx: -20 },
        y: { align: "bottom", dy: 20 }
    },

    friends: {
        width: 250,
        height: 80,
        x: { align: "left", dx: 20 },
        y: { align: "bottom", dy: 20 }
    },
};