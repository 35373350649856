/**
 * Created by Mikhail Menshenin on 21.08.2024
 */

var CoinMasterView = cc.Node.extend({
    ctor: function () {
        this._super();
        this.setAnchorPoint(0.5, 0.5);
        var styles = cleverapps.styles.CoinMasterView;
        this.setContentSize2(styles.size);

        var name = this.name = cleverapps.UI.generateOnlyText("Jonny", cleverapps.styles.FONTS.COINMASTER_NAME_TEXT);
        name.setAnchorPoint(0, 0.5);
        name.setPositionRound(styles.name);
        this.addChild(name);

        var gold = this.gold = cleverapps.UI.generateOnlyText("876,000", cleverapps.styles.FONTS.COINMASTER_GOLD_TEXT);
        gold.setAnchorPoint(0, 0.5);
        gold.setPositionRound(styles.gold);
        this.addChild(gold);

        var village = aisensia.villageManager.getRaidVillage();
        village.on("updatePlayer", this.createListener(this.updateRaidPlayer.bind(this, village)));
        this.updateRaidPlayer(village);
    },

    updateRaidPlayer: function (village) {
        var styles = cleverapps.styles.CoinMasterView;
        var player = village.player;
        var name = player.name;
        var gold = village.gold;

        if (this.avatar) {
            this.avatar.removeFromParent();
        }

        var avatar = this.avatar = new cleverapps.UI.Avatar(player);
        avatar.setPositionRound(styles.avatar);
        avatar.setScale(styles.avatar.scale);
        this.addChild(avatar);

        this.name.setString(cleverapps.UI.cutPlayerName(name, 7));
        this.gold.setString(aisensia.getNumberWithCommas(gold));
    },

    animateRaidPlayer: function (target) {
        cleverapps.audio.playSound(bundles.game.urls.slot_vs);

        var flyIcon = this.avatar;
        var rect = flyIcon.getSceneBoundingBox();
        var scale = Math.max(target.width / rect.width, target.height / rect.height);
        flyIcon.runAction(new cc.Sequence(
            AnimationsLibrary.animateCollect(flyIcon, target, {
                duration: 0.5,
                scale: scale
            }),
            new cc.RemoveSelf()
        ));
    }
});

cleverapps.styles.FONTS = cleverapps.overrideFonts(cleverapps.styles.FONTS, {
    COINMASTER_NAME_TEXT: {
        size: 30,
        color: cleverapps.styles.COLORS.WHITE
    },
    COINMASTER_GOLD_TEXT: {
        name: "nostroke",
        size: 30,
        color: new cc.Color(253, 214, 47, 255)
    }
});

cleverapps.styles.CoinMasterView = {
    size: {
        width: 241,
        height: 104
    },

    avatar: {
        scale: 0.81,
        x: { align: "left", dx: 0 },
        y: { align: "center", dy: 2 }
    },

    name: {
        x: { align: "left", dx: 110 },
        y: { align: "center", dy: 25 }
    },

    gold: {
        x: { align: "left", dx: 110 },
        y: { align: "center", dy: -20 }
    }
};